@import '../abstracts/mixins';

.helpDialog {
  .helpDialogCloseButton {
    position: absolute;
    top: 5px;
    right: 5px;
  }
  .helpDialogInner {
    width: 100%;
    margin-top: 65px;
    padding: 17px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    .helpDialogHeader {
      margin-top: 32px;
      .helpDialogHeaderText {
        text-align: center;
        h3 {
          color: #323232;
          font-family: 'Poppins', sans-serif;
          font-size: 24px;
          font-weight: 700;
          line-height: 29px;
          letter-spacing: 0px;
          text-align: center;
        }
        span {
          display: block;
          margin-top: 8px;
          color: #636363;
          font-family: 'Poppins', sans-serif;
          font-size: 18px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0px;
          text-align: center;
          @include media(mobile) {
            font-size: 16px;
          }
        }
      }
    }
    .chatButtonText {
      font-family: 'Poppins', sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0px;
      text-align: left;
      text-transform: capitalize;
    }
    .helpDialogText {
      display: flex;
      margin-top: auto;
      font-family: 'Lato', sans-serif;
      font-size: 16px;
      line-height: 19.2px;
      color: #555555;
      .helpLink {
        color: #443461;
        margin-left: 3px;
        padding: 0px;
        min-width: auto;
        font-weight: 700;
        font-size: 16px;
        line-height: 19.2px;
        text-decoration: none;
      }
      &.MuiButton-root:hover {
        background: none;
      }
    }
  }
}
